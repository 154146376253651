import React, { useMemo } from 'react';
import { Box, Typography } from '@heycater/design-system';
import Image from 'next/image';
import styled from 'styled-components';

import { useQualification } from 'qualification/context';
import { QUALIFICATION_STATUS } from 'qualification/context/model';
import { SCHEMA_STEPS } from 'qualification/schema/steps';
import useLocalizedFieldName from 'shared/hooks/useLocalizedFieldName';
import QuantityStepperField from 'system/components/QuantityStepperField';

const ICON_SIZE_IN_PX = 30;
const step = SCHEMA_STEPS.dietary_restrictions;

interface Props {
  question: typeof step['questions'][number];
  autoFocus: boolean;
}

// eslint-disable-next-line react/display-name
const DietaryStepper = ({ question, autoFocus }: Props) => {
  const { localizedAttributeName } = useLocalizedFieldName();
  const { state, actions, answerOf } = useQualification();

  const localizedOptions = useMemo(
    () => ({
      image: question?.content?.image,
      //@ts-expect-error TODO: type the function
      label: question.content[localizedAttributeName('title')],
    }),
    [question.content]
  );

  if (state.status !== QUALIFICATION_STATUS.answering) {
    return null;
  }

  const answer = answerOf(question.id);

  const handleChange = (value: number | string) => {
    actions.answerQuestion(question.id, value);
  };

  return (
    <Box my={2}>
      <GridWrapper>
        <Box
          display="flex"
          ml={{ xs: `-${ICON_SIZE_IN_PX}px`, sm: 0 }}
          justifySelf={{ xs: 'center', sm: 'start' }}
          alignItems={'center'}
          justifyContent={{ xs: 'center', sm: 'start' }}
        >
          {localizedOptions?.image && (
            <Image
              alt=""
              src={localizedOptions?.image}
              width={ICON_SIZE_IN_PX}
              height={ICON_SIZE_IN_PX}
            />
          )}

          <Typography variant="body1" pl={1}>
            {localizedOptions.label}
          </Typography>
        </Box>
        <Box>
          <QuantityStepperField
            autoFocus={autoFocus}
            name={question.id}
            id={question.id}
            value={answer}
            onChange={handleChange}
          />
        </Box>
      </GridWrapper>
    </Box>
  );
};

export default DietaryStepper;

const GridWrapper = styled.div`
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(2)}px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    grid-template-columns: 1fr;
    gap: ${({ theme }) => theme.spacing(2)}px;
  }
`;
