import React from 'react';
import { Box, Button, Typography } from '@heycater/design-system';
import { Grid, useMediaQuery } from '@material-ui/core';
import { VerifiedUser } from '@material-ui/icons';
import Image from 'next/image';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useQualification } from 'qualification/context';
import Container from 'system/components/Container';

export default function CustomerTierRare() {
  const { t } = useTranslation();
  const mobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const {
    actions: { nextStep },
  } = useQualification();

  return (
    <Container size={800} disableGutters>
      <Box
        style={{
          borderRadius: '16px',
          marginTop: '90px',
        }}
      >
        <Grid container>
          <InfoBox item xs={12} sm={6} mobile={mobile}>
            <TitleTypography mb={2}>
              {t('qualification:rareCustomerTier.v1.benefits.title')}
            </TitleTypography>
            <BenefitPointBox>
              <VerifiedUser />
              <Typography ml={2} mb={2}>
                {t('qualification:rareCustomerTier.v1.benefits.first')}
              </Typography>
            </BenefitPointBox>
            <BenefitPointBox>
              <VerifiedUser />
              <Typography ml={2} mb={2}>
                {t('qualification:rareCustomerTier.v1.benefits.second')}
              </Typography>
            </BenefitPointBox>
            <BenefitPointBox>
              <VerifiedUser />
              <Typography ml={2} mb={2}>
                {t('qualification:rareCustomerTier.v1.benefits.third')}
              </Typography>
            </BenefitPointBox>
            <BenefitPointBox>
              <VerifiedUser />
              <Typography ml={2} mb={2}>
                {t('qualification:rareCustomerTier.v1.benefits.fourth')}
              </Typography>
            </BenefitPointBox>
          </InfoBox>
          <ServiceBox item xs={12} sm={6} mobile={mobile}>
            <ImageBox>
              <Image
                priority
                alt="Service"
                src="/artifacts/images/heycater_service.jpeg"
                width="130px"
                height="130px"
                objectFit="cover"
              />
            </ImageBox>
            <DetailedBox>
              <TitleTypography pt={3}>
                {t('qualification:rareCustomerTier.v1.service.title')}
              </TitleTypography>
              <ServiceSubtitle>
                <Trans
                  t={t}
                  i18nKey="qualification:rareCustomerTier.v1.service.subtitle"
                  components={[<strong key="strong" />]}
                />
              </ServiceSubtitle>
              <StyledButton onClick={nextStep}>
                {t('qualification:rareCustomerTier.v1.service.button')}
              </StyledButton>
              <FooterBox>
                <Trans
                  t={t}
                  i18nKey="qualification:rareCustomerTier.v1.service.footer"
                  components={[<strong key="strong" />]}
                />
              </FooterBox>
            </DetailedBox>
          </ServiceBox>
        </Grid>
      </Box>
    </Container>
  );
}

const StyledButton = styled(Button)`
  border-radius: 47px;
`;

const FooterBox = styled(Box)`
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: ${({ theme }) => theme.spacing(2)}px;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
`;

const TitleTypography = styled(Box)`
  font-size: ${({ theme }) => theme.spacing(3)}px;
  font-style: normal;
  font-weight: 700;
  line-height: ${({ theme }) => theme.spacing(5)}px;
`;

const ServiceSubtitle = styled(Typography)`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: ${({ theme }) => theme.spacing(3)}px;
  margin: ${({ theme }) => theme.spacing(3)}px;
`;

const ImageBox = styled(Box)`
  position: absolute;
  top: -55px;
  left: 35%;
  width: fit-content;
  img {
    border-radius: 50%;
  }
`;

const DetailedBox = styled(Box)`
  max-width: 345px;
  margin: auto;
  margin-top: 55px;
  text-align: center;
`;

const BenefitPointBox = styled(Box)`
  svg {
    font-size: 42px;
    background: #f0f0f0;
    border-radius: 50%;
    padding: 10px;
  }
  display: flex;
`;

const InfoBox = styled(Grid)`
  padding: ${({ mobile, theme }) =>
    mobile ? theme.spacing(2, 2) : theme.spacing(1.5, 7.5)};
  padding-bottom: 0;
  width: 40%;
  order: ${(props) => (props.mobile ? '2' : '1')};
`;

const ServiceBox = styled(Grid)`
  width: 60%;
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  background: #f8f8f8;
  padding: ${({ theme }) => theme.spacing(3, 7 / 5)};
  position: relative;
  order: ${(props) => (props.mobile ? '1' : '2')};
`;
