import { useRouter } from 'next/router';
import { TFunction } from 'next-i18next';
import { useTranslation } from 'react-i18next';

import { useQualification } from 'qualification/context';
import { Answers, StepState } from 'qualification/context/model';
import { QuestionId, StepId } from 'qualification/schema/model';
import { CATERING_CATEGORY_ITEMS } from 'qualification/schema/questions/cateringCategoriesQuestion';
import { CITY_QUESTION_ITEMS } from 'qualification/schema/questions/cityQuestion';
import { LEAD_TYPE_ITEMS } from 'qualification/schema/questions/leadTypeQuestion';
import { formatDate } from 'shared/helpers/dates';

interface UsePillValueOptions {
  stepId: StepId;
  questionId?: QuestionId;
}

interface PillValue {
  stepId: StepId;
  questionId?: QuestionId;
  text?: string;
}

export const usePillValue = ({
  stepId,
  questionId,
}: UsePillValueOptions): null | PillValue | PillValue[] => {
  const { state } = useQualification();
  const { locale } = useRouter();
  const { t } = useTranslation('qualification');

  if (!('qualification' in state)) {
    return null;
  }

  return getPillValue({
    steps: state.qualification.steps,
    answers: state.qualification.answers,
    t,
    locale,
    stepId: stepId,
    questionId: questionId,
  });
};

export const usePillValues = (options: UsePillValueOptions[]): PillValue[] => {
  const { state } = useQualification();
  const { locale } = useRouter();
  const { t } = useTranslation('qualification');

  if (!('qualification' in state)) {
    return [];
  }

  const pillValues = options
    .map(({ stepId: questionId, questionId: childQuestionId }) =>
      getPillValue({
        steps: state.qualification.steps,
        answers: state.qualification.answers,
        t,
        locale,
        stepId: questionId,
        questionId: childQuestionId,
      })
    )
    .flat()
    .filter((truthy) => !!truthy) as PillValue[];
  return pillValues;
};

interface getPillValueOptions {
  steps: StepState[];
  answers: Answers;
  locale?: string;
  stepId: StepId;
  questionId?: QuestionId;
  t: TFunction;
}

function getPillValue({
  t,
  steps,
  answers,
  locale,
  stepId,
  questionId,
}: getPillValueOptions): PillValue | PillValue[] | null {
  const step = steps.find((q) => q.id === stepId);

  if (!step) {
    return null;
  }

  switch (step.id) {
    case 'city': {
      const answer = answers?.city;
      const item = CITY_QUESTION_ITEMS.find(
        (leadTypeItem) => leadTypeItem.value === answer
      );
      return item
        ? {
            stepId: stepId,
            text: getItemLabel(locale, item),
          }
        : null;
    }
    case 'catering_categories': {
      const items =
        answers?.catering_categories
          ?.map((category) =>
            CATERING_CATEGORY_ITEMS.find((i) => i.value === category)
          )
          .filter((v) => !!v) || [];
      return items.map((item) => ({
        stepId: stepId,
        questionId: questionId,
        text: getItemLabel(locale, item as NonNullable<typeof item>),
      }));
    }
    case 'event_date': {
      const [date, leadType] = [answers.event_date, answers.lead_type];
      const leadTypeItem = LEAD_TYPE_ITEMS.find(
        (leadTypeItem) => leadTypeItem.value === leadType
      );
      const returnValue = [
        {
          stepId: stepId,
          text: formatDate(date, locale) || '',
        },
      ];
      if (leadTypeItem) {
        returnValue.push({
          stepId: step.id,
          text: getItemLabel(locale, leadTypeItem),
        });
      }
      return returnValue;
    }
    case 'people_count_and_budget': {
      const [total, pricePerPerson, peopleCount] = [
        answers.caterer_minimum_order_value,
        answers.price_per_person,
        answers.min_order_number,
      ];
      return [
        {
          stepId: stepId,
          text: t('qualification:selection.people', { amount: peopleCount }),
        },
        {
          stepId: stepId,
          text: t('qualification:selection.budget', {
            perPerson: pricePerPerson,
            total: total,
          }),
        },
      ];
    }
    case 'dietary_restrictions': {
      if (questionId === 'number_of_carnivore') {
        return {
          stepId: stepId,
          questionId: questionId,
          text: t('qualification:selection.carnivore', {
            amount: answers.number_of_carnivore,
          }),
        };
      }
      if (questionId === 'number_of_vegans') {
        return {
          stepId: stepId,
          questionId: questionId,
          text: t('qualification:selection.vegan', {
            amount: answers.number_of_vegans,
          }),
        };
      }
      if (questionId === 'number_of_vegetarians') {
        return {
          stepId: stepId,
          questionId: questionId,
          text: t('qualification:selection.vegetarian', {
            amount: answers.number_of_vegetarians,
          }),
        };
      }
      return null;
    }
    default: {
      return null;
    }
  }
}

function getItemLabel<T extends { labelDe: string; labelEn: string }>(
  locale: string | undefined,
  item: T
): string {
  if (locale === 'de') {
    return item.labelDe;
  }
  return item.labelEn;
}
