import React from 'react';

import { Pill, PillSize } from 'qualification/components/PillLabel/Pill';
import { usePillValue } from 'qualification/components/PillLabel/usePillValue';
import { useQualification } from 'qualification/context';
import { QUALIFICATION_STATUS } from 'qualification/context/model';
import { QuestionId, StepId } from 'qualification/schema/model';

interface Props {
  stepId: StepId;
  questionId?: QuestionId;
  text: string;
  size?: PillSize;
}

function PillLabel({ stepId, questionId, text, size }: Props) {
  const value = usePillValue({ stepId: stepId, questionId: questionId });
  const { actions, state } = useQualification();
  const step =
    state.status === 'answering' &&
    state.qualification.steps.find((_step) => _step.id === stepId);

  const isPillClickable = Boolean(
    state.status === QUALIFICATION_STATUS.answering && step && !step?.disabled
  );

  const onClick = isPillClickable
    ? () => actions.setQuestion(stepId)
    : undefined;

  if (!value) {
    return null;
  }

  return (
    <Pill size={size} onClick={onClick}>
      {text}
    </Pill>
  );
}

export default PillLabel;
