import React from 'react';
import styled from 'styled-components';

const AppSwitch = (
  props: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
) => {
  return (
    <SwitchLabelWrapper>
      <input type="checkbox" {...props} />
      <span />
    </SwitchLabelWrapper>
  );
};

const SwitchLabelWrapper = styled.label`
  position: relative;
  display: inline-block;
  width: 28px;
  height: 14px;
  border-radius: 100px;
  flex-shrink: 0;

  input {
    opacity: 0;
    width: 0;
    height: 0;
    border-radius: 34px;
  }

  span {
    position: absolute;
    border-radius: 100px;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.3s;
    transition: 0.3s;

    &:before {
      position: absolute;
      content: '';
      height: 10px;
      width: 10px;
      left: 2px;
      bottom: 2px;
      border-radius: 50%;
      background-color: white;
      -webkit-transition: 0.3s;
      transition: 0.3s;
    }
  }

  input:checked + span {
    background-color: ${({ theme }) => theme.palette.secondary.light};
  }

  input:checked + span:before {
    -webkit-transform: translateX(14px);
    -ms-transform: translateX(14px);
    transform: translateX(14px);
  }
`;

export default AppSwitch;
