import React from 'react';
import { Box } from '@heycater/design-system';
import { IconButton, Input } from '@material-ui/core';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';
import styled, { css } from 'styled-components';

import useInputFocusOnMount from 'shared/hooks/useInputFocusOnMount';

interface Props {
  name: string;
  id: string;
  value?: number;
  error?: boolean;
  defaultValue?: number;
  onChange: (value: number | string) => void;
  placeholder?: string;
  autoFocus?: boolean;
}

const QuantityStepperField = ({
  name,
  id,
  value,
  defaultValue,
  onChange,
  error,
  placeholder,
  autoFocus = true,
}: Props) => {
  const { inputRef } = useInputFocusOnMount({ shouldFocus: autoFocus });

  const handleChange = (nextValue: string | number) => {
    onChange(Number(nextValue));
  };

  const onDecrement = () => {
    handleChange((value ?? 0) - 1);
  };

  const onIncrement = () => {
    handleChange((value ?? 0) + 1);
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <IconButtonLeft
        onClick={onDecrement}
        id={`${id}-decrement`}
        aria-label="Minus"
        role="button"
        disabled={!value}
      >
        <RemoveOutlinedIcon />
      </IconButtonLeft>
      <StepperInput
        inputRef={inputRef}
        name={name}
        disableUnderline
        error={error}
        placeholder={placeholder}
        type="tel"
        defaultValue={defaultValue}
        onChange={(event) => handleChange(event.target.value)}
        value={value}
        autoComplete="off"
      />
      <IconButtonRight
        onClick={onIncrement}
        id={`${id}-increment`}
        aria-label="Plus"
        role="button"
      >
        <AddOutlinedIcon />
      </IconButtonRight>
    </Box>
  );
};

export const StepperInput = styled(Input)`
  .MuiInputBase-input {
    text-align: center;
    background-color: ${(props) => props.theme.palette.text.disabled};
    border: 1px solid transparent;
    border-radius: ${(props) => props.theme.spacing(1)}px;
    height: 49px;
    width: 110px;
    box-sizing: border-box;

    &:hover {
      border-color: ${(props) => props.theme.palette.grey[400]};
    }

    &:focus,
    &:active {
      border-color: ${(props) => props.theme.palette.grey[500]};
    }

    ${({ error }) =>
      !!error &&
      css`
        border-color: #e86202;
        &:hover,
        &:focus,
        &:active {
          border-color: #e86202;
        }
      `}
  }
`;

export const StepperIconButton = styled(IconButton)`
  background-color: ${(props) => props.theme.palette.grey[200]};
  color: ${(props) => props.theme.palette.grey[600]};
  border-radius: ${(props) => props.theme.spacing(1)}px;
  height: 49px;
  width: 49px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: manipulation;

  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.palette.grey[300]};
  }

  &:active {
    background-color: ${(props) => props.theme.palette.grey[400]};
  }
  .MuiSvgIcon-root {
    font-size: 18px;
  }
`;

const IconButtonRight = styled(StepperIconButton)`
  margin-left: ${(props) => props.theme.spacing(1)}px;
`;

const IconButtonLeft = styled(StepperIconButton)`
  margin-right: ${(props) => props.theme.spacing(1)}px;
`;

export default QuantityStepperField;
