import React, { ReactNode } from 'react';
import { Typography } from '@heycater/design-system';
import styled from 'styled-components';

import { useBreakpointDown } from 'qualification/hooks/useBreakpoints';

export type PillSize = 'sm' | 'md';
interface Props {
  children: ReactNode;
  onClick?: () => void;
  id?: string;
  size?: PillSize;
}

export const Pill = ({ children, onClick, id, size }: Props) => {
  const mobile = useBreakpointDown('xs');
  const isSmall = size === 'sm' || mobile;
  return (
    <StyledPillar
      $size={size || 'md'}
      id={id}
      type="button"
      disabled={!onClick}
      onClick={onClick}
    >
      <Typography
        component="div"
        variant="caption"
        lineHeight={'170%'}
        fontSize={isSmall ? 10 : 12}
      >
        {children}
      </Typography>
    </StyledPillar>
  );
};

export const StyledPillar = styled.button<{ $size: PillSize }>`
  display: inline-flex;
  flex-wrap: nowrap;
  border: none;
  flex-direction: row;
  align-items: flex-start;
  padding: ${(props) =>
    props.$size === 'sm'
      ? props.theme.spacing(0.5, 1)
      : props.theme.spacing(1, 2)};
  gap: ${(props) => props.theme.spacing(0.5)}px;
  background: #f0f0f0;
  border-radius: 66px;
  white-space: nowrap;
  line-height: 170%;
  transition: color 0.2s, background-color 0.2s;

  :enabled {
    cursor: pointer;

    :hover,
    :active,
    :focus {
      background-color: ${(props) => props.theme.palette.grey.secondary};
      color: white;
    }
  }

  ${(props) => props.theme.breakpoints.down('xs')} {
    padding: ${(props) => props.theme.spacing(0.5, 1)};
  }
`;
