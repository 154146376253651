import React from 'react';
import { Box, Typography } from '@heycater/design-system';
import { IconButton, Input } from '@material-ui/core';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';
import styled from 'styled-components';
import { css } from 'styled-components';

import useInputFocusOnMount from 'shared/hooks/useInputFocusOnMount';

interface Props {
  name: string;
  id?: string;
  min?: number;
  error?: boolean;
  value: number;
  onChange: (val: number) => void;
  placeholder?: string;
  autoFocus?: boolean;
}

function PriceStepperField({
  name,
  min,
  id,
  error,
  value,
  onChange,
  placeholder,
  autoFocus,
}: Props) {
  const { inputRef } = useInputFocusOnMount({ shouldFocus: !!autoFocus });

  const minValue = min || 0;

  const isInputValid = (value: string | number) => {
    const nextValue = Number(value);

    if (isNaN(nextValue)) {
      return false;
    }

    if (nextValue < minValue) {
      return false;
    }

    return true;
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const nextValue = Number(event.target.value);

    if (isInputValid(nextValue)) {
      onChange(nextValue);
    }
  };

  const onDecrement = () => {
    isInputValid(value - 1) && onChange(value - 1);
  };

  const onIncrement = () => {
    isInputValid(value + 1) && onChange(value + 1);
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <IconButtonLeft
        onClick={onDecrement}
        id={`${id}-decrement`}
        aria-label="Minus"
        role="button"
        disabled={value <= minValue}
      >
        <RemoveOutlinedIcon />
      </IconButtonLeft>
      <InputWithAdornment>
        <StepperInput
          inputRef={inputRef}
          name={name}
          id={id}
          error={!!error}
          disableUnderline
          placeholder={placeholder}
          type="tel"
          onChange={handleChange}
          value={Number(value)}
          autoComplete="off"
        />
        <Adornment>
          <Typography>€</Typography>
        </Adornment>
      </InputWithAdornment>
      <IconButtonRight
        onClick={onIncrement}
        id={`${id}-increment`}
        aria-label="Plus"
        role="button"
      >
        <AddOutlinedIcon />
      </IconButtonRight>
    </Box>
  );
}

export const StepperInput = styled(Input)`
  .MuiInputBase-input {
    text-align: center;
    background-color: ${(props) => props.theme.palette.text.disabled};
    border: 1px solid transparent;
    border-radius: ${(props) => props.theme.spacing(1)}px;
    height: 49px;
    width: 110px;
    box-sizing: border-box;

    &:hover {
      border-color: ${(props) => props.theme.palette.grey[400]};
    }

    &:focus,
    &:active {
      border-color: ${(props) => props.theme.palette.grey[500]};
    }

    ${({ error }) =>
      !!error &&
      css`
        border-color: #e86202;
        &:hover,
        &:focus,
        &:active {
          border-color: #e86202;
        }
      `}
  }
`;

export const StepperIconButton = styled(IconButton)`
  background-color: ${(props) => props.theme.palette.grey[200]};
  color: ${(props) => props.theme.palette.grey[600]};
  border-radius: ${(props) => props.theme.spacing(1)}px;
  height: 49px;
  width: 49px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: manipulation;

  &:hover {
    background-color: ${(props) => props.theme.palette.grey[300]};
  }

  .MuiSvgIcon-root {
    font-size: 18px;
  }
`;

const IconButtonRight = styled(StepperIconButton)`
  margin-left: ${(props) => props.theme.spacing(2)}px;
`;

const IconButtonLeft = styled(StepperIconButton)`
  margin-right: ${(props) => props.theme.spacing(2)}px;
`;

const InputWithAdornment = styled.div`
  position: relative;
`;

const Adornment = styled.div`
  position: absolute;
  right: ${(props) => props.theme.spacing(2)}px;
  top: 50%;
  transform: translateY(-50%);
`;

export default PriceStepperField;
