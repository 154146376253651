import { useRouter } from 'next/router';

import {
  algoliaAttributeNameForLocale,
  attributeNameForLocale,
} from 'shared/helpers/localizedFieldNames';

export default function useLocalizedFieldName() {
  const router = useRouter();

  const localizedFieldAlgolia = algoliaAttributeNameForLocale(router.locale);
  const localizedAttributeName = attributeNameForLocale(router.locale);

  return {
    localizedFieldAlgolia,
    localizedAttributeName,
  };
}
