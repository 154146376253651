import React, { useState } from 'react';
import { Box, Typography } from '@heycater/design-system';
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  useMediaQuery,
} from '@material-ui/core';
import {
  PersonOutline,
  QueryBuilder,
  SentimentSatisfied,
  StarBorder,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { EXPERIMENT, isVariationActive } from 'lib/ablyft';
import { useQualification } from 'qualification/context';
import { customerTier } from 'qualification/schema/questions/customerTierQuestion';
import Container from 'system/components/Container';

export default function CustomerTier() {
  const { t } = useTranslation();
  const {
    actions: {
      answerQuestion,
      addRareCustomerTierStep,
      removeRareCustomerTierStep,
    },
    state,
  } = useQualification();
  const [value, setValue] = useState(state.qualification.answers.customer_tier);
  const mobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const handleChange = (event) => {
    const tier = event.target.value;
    setValue(tier);
    if ([customerTier.rareV1, customerTier.rareV2].includes(tier)) {
      addRareCustomerTierStep();
    } else {
      removeRareCustomerTierStep();
    }
    answerQuestion('customer_tier', tier);
  };

  const formOptions = [
    {
      title: 'qualification:customerTier.form.options.rarely.title',
      subtitle: 'qualification:customerTier.form.options.rarely.subtext',
      value: isVariationActive(EXPERIMENT.customerTierABC.variations.variationB)
        ? customerTier.rareV1
        : customerTier.rareV2,
    },
    {
      title: 'qualification:customerTier.form.options.regularly.title',
      subtitle: 'qualification:customerTier.form.options.regularly.subtext',
      value: customerTier.regular,
    },
    {
      title: 'qualification:customerTier.form.options.frequently.title',
      subtitle: 'qualification:customerTier.form.options.frequently.subtext',
      value: customerTier.frequent,
    },
  ];

  return (
    <Container size={905} disableGutters>
      <Box
        style={{
          backgroundColor: '#FFF',
          borderRadius: '16px',
        }}
      >
        <Grid container>
          <InfoBox item xs={12} sm={5} mobile={mobile}>
            <InfoHeader>
              {t('qualification:customerTier.benefits.title')}
            </InfoHeader>
            <BenefitPointBox>
              <PersonOutline />
              <Typography ml={2}>
                {t('qualification:customerTier.benefits.personal')}
              </Typography>
            </BenefitPointBox>
            <BenefitPointBox>
              <StarBorder />
              <Typography ml={2}>
                {t('qualification:customerTier.benefits.premium')}
              </Typography>
            </BenefitPointBox>
            <BenefitPointBox>
              <SentimentSatisfied />
              <Typography ml={2}>
                {t('qualification:customerTier.benefits.satisfaction')}
              </Typography>
            </BenefitPointBox>
            <BenefitPointBox>
              <QueryBuilder />
              <Typography ml={2}>
                {t('qualification:customerTier.benefits.customisations')}
              </Typography>
            </BenefitPointBox>
          </InfoBox>
          <FormBox item xs={12} sm={7} mobile={mobile}>
            <Title>{t('qualification:customerTier.form.title')}</Title>
            <Box my={3}>
              <FormSubtitle variant="caption">
                {t('qualification:customerTier.form.subtitle')}
              </FormSubtitle>
            </Box>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={value}
              onChange={handleChange}
            >
              {formOptions.map(
                ({ title, subtitle, value: optionValue }, index) => {
                  return (
                    <StyledFormControlLabel
                      key={index}
                      value={optionValue}
                      control={<Radio />}
                      active={value === optionValue}
                      label={
                        <Box ml={1}>
                          <LabelTitle>{t(title)}</LabelTitle>
                          <LabelSubtext variant="caption">
                            {t(subtitle)}
                          </LabelSubtext>
                        </Box>
                      }
                    />
                  );
                }
              )}
            </RadioGroup>
          </FormBox>
        </Grid>
      </Box>
    </Container>
  );
}

const BenefitPointBox = styled(Box)`
  svg {
    font-size: ${({ theme }) => theme.spacing(4.5)}px;
    background: #f0f0f0;
    border-radius: 50%;
    padding: 6px;
  }
  margin: ${({ theme }) => theme.spacing(2, 0)};
  display: flex;
`;

const InfoHeader = styled(Typography)`
  font-weight: 600;
  line-height: 27px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

const InfoBox = styled(Grid)`
  padding: ${({ mobile, theme }) =>
    mobile ? theme.spacing(2, 2) : theme.spacing(3, 7.5)};
  order: ${(props) => (props.mobile ? '2' : '1')};
`;

const FormBox = styled(Grid)`
  width: 60%;
  border-radius: 8px;
  background: #f8f8f8;
  padding: ${({ mobile, theme }) =>
    mobile ? theme.spacing(2, 2) : theme.spacing(3, 7.5)};
  order: ${(props) => (props.mobile ? '1' : '2')};
`;

const Title = styled(Typography)`
  font-size: ${({ theme }) => theme.spacing(2.5)}px;
  font-weight: 700;
  line-height: 34px;
`;

const FormSubtitle = styled(Typography)`
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.4px;
  margin: ${({ theme }) => theme.spacing(3, 0)};
`;

const LabelTitle = styled(Typography)`
  display: inline;
  font-weight: 700;
`;

const LabelSubtext = styled(Typography)`
  display: inline;
  margin-left: ${({ theme }) => theme.spacing(1)}px;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  background: ${(props) => (props.active ? ' #9CBA6C' : '#E3E4E4')};
  border-radius: ${({ theme }) => theme.spacing(2)}px;
  margin: ${({ theme }) => theme.spacing(1, 0)};
  padding: ${({ theme }) => theme.spacing(0.5, 2)};
  .MuiButtonBase-root {
    padding: 0;
  }
  svg {
    font-size: 27px;
    color: #3c4340;
  }
`;
