import React from 'react';

import { EXPERIMENT, isVariationActive } from 'lib/ablyft';
import CustomerTierRareV1 from 'shared/components/EmbeddedFunnel/experiments/rare_tier/v1';
import CustomerTierRareV2 from 'shared/components/EmbeddedFunnel/experiments/rare_tier/v2';

export default function CustomerRareTier() {
  if (isVariationActive(EXPERIMENT.customerTierABC.variations.variationB))
    return <CustomerTierRareV1 />;

  if (isVariationActive(EXPERIMENT.customerTierABC.variations.variationC))
    return <CustomerTierRareV2 />;

  return null;
}
