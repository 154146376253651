import React, { useEffect } from 'react';
import { Box, Typography } from '@heycater/design-system';
import { FormControlLabel, Tooltip, useTheme } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import 'react-day-picker/dist/style.css';

import { useQualification } from 'qualification/context';
import { QUALIFICATION_STATUS } from 'qualification/context/model';
import useCurrentUser from 'qualification/hooks/useCurrentUser';
import { questions } from 'qualification/schema/questions';
import AppSwitch from 'system/components/AppSwitch';

function DatePickerSide() {
  const { t } = useTranslation('qualification');
  const { actions, state, answerOf } = useQualification();
  const theme = useTheme();

  const { currentUserAccount } = useCurrentUser();
  const isLoggedIn = !!currentUserAccount;
  const showLeadTypeCheckbox = questions.leadType.condition?.(isLoggedIn);

  useEffect(() => {
    if (answerOf('lead_type') === undefined)
      actions.answerQuestion('lead_type', 'private');
  }, [answerOf]);

  if (state.status !== QUALIFICATION_STATUS.answering) {
    return null;
  }

  return (
    <SwitchesWrapper>
      <FormControlLabel
        control={
          <AppSwitch
            name="multi-days"
            checked={answerOf('is_multi_day')}
            onChange={(value) =>
              actions.answerQuestion('is_multi_day', value.target.checked)
            }
          />
        }
        label={
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <LabelTypography variant="body2" ml={1.5} mr={2} pt={0.25} pl={1}>
              {t('questionTypes.multiDaySwitch.label')}
            </LabelTypography>
            <Tooltip title={t('questionTypes.multiDaySwitch.infoTooltip')}>
              <Box
                color={theme.palette.grey[600]}
                display="flex"
                alignItems="center"
              >
                <Info color="inherit" />
              </Box>
            </Tooltip>
          </Box>
        }
      />
      {showLeadTypeCheckbox && (
        <Box mt="auto">
          <FormControlLabel
            control={
              <AppSwitch
                name="lead-type"
                checked={answerOf('lead_type') === 'business'}
                onChange={(value) =>
                  actions.answerQuestion(
                    'lead_type',
                    value.target.checked ? 'business' : 'private'
                  )
                }
              />
            }
            label={
              <LabelTypography variant="body2" ml={1.5} pt={0.25} pl={1}>
                {t('questionTypes.leadType.switch')}
              </LabelTypography>
            }
          />
        </Box>
      )}
    </SwitchesWrapper>
  );
}

const LabelTypography = styled(Typography)`
  font-size: 14px;
`;

const SwitchesWrapper = styled.div`
  display: flex;
  max-width: 340px;
  flex-direction: column;
  background-color: #f8f8f8;
  border-radius: 0.5rem;
  padding: 2rem;
  padding-left: 2.5rem;
  padding-right: 0.5rem;
  gap: 1rem;
  text-align: left;

  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: 320px;
  }
`;

export default DatePickerSide;
