import { QuestionId, StepId } from 'qualification/schema/model';

interface SelectionSchemaItem {
  stepId: StepId;
  questionId?: QuestionId;
}

export const selectionSchema: SelectionSchemaItem[] = [
  {
    stepId: 'city',
  },
  {
    stepId: 'catering_categories',
  },
  {
    stepId: 'event_date',
  },
  {
    stepId: 'people_count_and_budget',
  },
  {
    stepId: 'dietary_restrictions',
    questionId: 'number_of_vegetarians',
  },

  {
    stepId: 'dietary_restrictions',
    questionId: 'number_of_vegans',
  },
  {
    stepId: 'dietary_restrictions',
    questionId: 'number_of_carnivore',
  },
];
