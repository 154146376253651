import { useMemo } from 'react';

import { usePillValues } from 'qualification/components/PillLabel/usePillValue';
import { selectionSchema } from 'qualification/components/selectionSchema';
import { useQualification } from 'qualification/context';
import { QUALIFICATION_STATUS } from 'qualification/context/model';

interface Options {
  display: 'all-answers' | 'previous-answers';
}

export function useAnswerPills({ display }: Options) {
  const { state } = useQualification();

  const pills = useMemo(() => {
    if (
      state.status !== QUALIFICATION_STATUS.answering &&
      state.status !== QUALIFICATION_STATUS.answered &&
      state.status !== QUALIFICATION_STATUS.qualifiedForRequest
    ) {
      return [];
    }

    const orderedSelectionSchema = selectionSchema.map((schemaItem) => ({
      ...schemaItem,
      stepPosition: state.qualification.steps.findIndex(
        (step) => step.id === schemaItem.stepId
      ),
    }));

    orderedSelectionSchema.sort((a, b) => a.stepPosition - b.stepPosition);

    if (
      display === 'previous-answers' &&
      state.status === QUALIFICATION_STATUS.answering
    ) {
      const currentStep = state.qualification.step;

      const currentStepIndex = state.qualification.steps.findIndex(
        (step) => step.id === currentStep.id
      );

      const previousStepIds = state.qualification.steps
        .slice(0, currentStepIndex)
        .map((step) => step.id);

      return orderedSelectionSchema.filter((answer) =>
        previousStepIds.includes(answer.stepId)
      );
    }

    return orderedSelectionSchema;
  }, [display, state]);

  const pillValues = usePillValues(pills);

  return pillValues;
}
