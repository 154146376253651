import React from 'react';

import { EXPERIMENT, isVariationActive } from 'lib/ablyft';
import CustomerTierV1 from 'shared/components/EmbeddedFunnel/experiments/customer_tier/v1';

export default function CustomerTier() {
  if (isVariationActive(EXPERIMENT.customerTierABC.variations.variationC))
    return <CustomerTierV1 />;

  if (isVariationActive(EXPERIMENT.customerTierABC.variations.variationB))
    return <CustomerTierV1 />;

  return null;
}
