import React from 'react';
import { Box, Button, Typography } from '@heycater/design-system';
import { Grid, useMediaQuery } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import Cookies from 'js-cookie';
import Image from 'next/image';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useQualification } from 'qualification/context';
import { marketplaceURL } from 'shared/helpers/urls';
import Container from 'system/components/Container';

export default function CustomerTierRare() {
  const { t } = useTranslation();
  const mobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const {
    actions: { nextStep },
  } = useQualification();
  const navigateToMarketplace = () => {
    window.location = marketplaceURL('/catering');
    Cookies.set('customer_tier', 'rare-c-mp');
  };

  return (
    <Container size={800} disableGutters>
      <Box
        style={{
          borderRadius: '16px',
          marginTop: '90px',
        }}
      >
        <Grid container justifyContent="center">
          <InfoBox item xs={12} sm={6} mobile={mobile} mr={1}>
            <CardBox>
              <MacImageBox mobile={mobile}>
                <Image
                  priority
                  alt="Mac"
                  src="/artifacts/images/macbook.png"
                  width="220px"
                  height="130px"
                  objectFit="cover"
                />
              </MacImageBox>
              <DetailedBox>
                <TitleTypography pt={3} mb={2}>
                  {t('qualification:rareCustomerTier.v2.bookYourself.title')}
                </TitleTypography>
                <SutitleTypography>
                  {t('qualification:rareCustomerTier.v2.bookYourself.subtitle')}
                </SutitleTypography>
                <Box my={3}>
                  <BenefitPointBox>
                    <StyledCheck />
                    <BenefitTypography ml={2} mb={1}>
                      {t(
                        'qualification:rareCustomerTier.v2.bookYourself.bullets.first'
                      )}
                    </BenefitTypography>
                  </BenefitPointBox>
                  <BenefitPointBox>
                    <StyledCheck />
                    <BenefitTypography ml={2} mb={1}>
                      {t(
                        'qualification:rareCustomerTier.v2.bookYourself.bullets.second'
                      )}
                    </BenefitTypography>
                  </BenefitPointBox>
                  <BenefitPointBox>
                    <StyledCheck />
                    <BenefitTypography ml={2} mb={1}>
                      {t(
                        'qualification:rareCustomerTier.v2.bookYourself.bullets.third'
                      )}
                    </BenefitTypography>
                  </BenefitPointBox>
                </Box>
                <StyledButton onClick={navigateToMarketplace}>
                  {t('qualification:rareCustomerTier.v2.bookYourself.button')}
                </StyledButton>
              </DetailedBox>
            </CardBox>
          </InfoBox>
          <ServiceBox item xs={12} sm={6} mobile={mobile}>
            <CardBox>
              <SourceImageBox mobile={mobile}>
                <Image
                  priority
                  alt="Service"
                  src="/artifacts/images/heycater_service.jpeg"
                  width="130px"
                  height="130px"
                  objectFit="cover"
                />
              </SourceImageBox>
              <DetailedBox>
                <TitleTypography pt={3}>
                  {t('qualification:rareCustomerTier.v2.service.title')}
                </TitleTypography>
                <ServiceSubtitle>
                  <Trans
                    t={t}
                    i18nKey="qualification:rareCustomerTier.v2.service.subtitle"
                    components={[<strong key="strong" />]}
                  />
                </ServiceSubtitle>
                <Box my={3}>
                  <BenefitPointBox>
                    <StyledCheck />
                    <BenefitTypography ml={2} mb={1}>
                      {t(
                        'qualification:rareCustomerTier.v2.service.bullets.first'
                      )}
                    </BenefitTypography>
                  </BenefitPointBox>
                  <BenefitPointBox>
                    <StyledCheck />
                    <BenefitTypography ml={2} mb={1}>
                      {t(
                        'qualification:rareCustomerTier.v2.service.bullets.second'
                      )}
                    </BenefitTypography>
                  </BenefitPointBox>
                  <BenefitPointBox>
                    <StyledCheck />
                    <BenefitTypography ml={2} mb={1}>
                      {t(
                        'qualification:rareCustomerTier.v2.service.bullets.third'
                      )}
                    </BenefitTypography>
                  </BenefitPointBox>
                </Box>

                <StyledButton onClick={nextStep}>
                  {t('qualification:rareCustomerTier.v2.service.button')}
                </StyledButton>
                <FooterBox>
                  <Trans
                    t={t}
                    i18nKey="qualification:rareCustomerTier.v2.service.footer"
                    components={[<strong key="strong" />]}
                  />
                </FooterBox>
              </DetailedBox>
            </CardBox>
          </ServiceBox>
        </Grid>
      </Box>
    </Container>
  );
}

const StyledCheck = styled(Check)`
  color: #7d8f5c;
`;

const CardBox = styled(Box)`
  margin: ${({ theme }) => theme.spacing(0, 1)};
  background: #f8f8f8;
  height: 100%;
`;

const SutitleTypography = styled(Typography)`
  font-size: 14px;
  margin: ${({ theme }) => theme.spacing(3, 0)};
  text-align: center;
`;

const BenefitTypography = styled(Typography)`
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.4px;
`;

const StyledButton = styled(Button)`
  border-radius: 47px;
`;

const FooterBox = styled(Box)`
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: ${({ theme }) => theme.spacing(2)}px;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
`;

const TitleTypography = styled(Box)`
  font-size: ${({ theme }) => theme.spacing(3)}px;
  font-style: normal;
  font-weight: 700;
  line-height: ${({ theme }) => theme.spacing(5)}px;
  text-align: center;
`;

const ServiceSubtitle = styled(Typography)`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: ${({ theme }) => theme.spacing(3)}px;
  margin: ${({ theme }) => theme.spacing(3, 0)};
`;

const MacImageBox = styled(Box)`
  position: absolute;
  top: -55px;
  left: ${(props) => (props.mobile ? '22' : '26')}%;
  width: fit-content;
`;

const SourceImageBox = styled(Box)`
  position: absolute;
  top: -55px;
  left: ${(props) => (props.mobile ? '33' : '36')}%;
  width: fit-content;
  img {
    border-radius: 50%;
  }
`;

const DetailedBox = styled(Box)`
  max-width: 345px;
  margin: auto;
  margin-top: 85px;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

const BenefitPointBox = styled(Box)`
  svg {
    font-size: 20px;
  }
  font-size: 10px;
  display: flex;
`;

const InfoBox = styled(Grid)`
  display: grid;
  width: 50%;
  position: relative;
  max-width: 455px;
`;

const ServiceBox = styled(Grid)`
  width: 50%;
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  background: #f8f8f8;
  position: relative;
  max-width: 455px;
  margin-top: ${(props) => (props.mobile ? '80px' : 'auto')};
`;
