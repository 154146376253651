import { useEffect, useRef } from 'react';

interface Options {
  shouldFocus: boolean;
}

export default function useInputFocusOnMount(
  { shouldFocus }: Options = { shouldFocus: true }
) {
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (shouldFocus) {
      inputRef.current?.focus();
    }
  }, [shouldFocus]);

  return {
    inputRef,
  };
}
