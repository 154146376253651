import React, { useMemo } from 'react';
import { Box, Typography } from '@heycater/design-system';
import styled from 'styled-components';

import { useQualification } from 'qualification/context';
import { peopleCountQuestion } from 'qualification/schema/questions/peopleCountQuestion';
import useLocalizedFieldName from 'shared/hooks/useLocalizedFieldName';

function PopularValues() {
  const { actions } = useQualification();
  const { localizedAttributeName } = useLocalizedFieldName();

  const localizedOptions =
    useMemo(
      () =>
        peopleCountQuestion.suggestions?.map((option) => ({
          value: option.value,
          // @ts-expect-error TODO: type this function
          label: option[localizedAttributeName('label')],
        })),
      []
    ) || [];
  return (
    <FlexBox
      flexWrap="wrap"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      id={peopleCountQuestion.id}
    >
      {localizedOptions.map((option, index) => (
        <Box
          key={`${option.value}::${index}`}
          onClick={() =>
            actions.answerQuestion(peopleCountQuestion.id, option.value)
          }
        >
          <StyledTypography variant="caption">{option.label}</StyledTypography>
        </Box>
      ))}
    </FlexBox>
  );
}

export default PopularValues;

const FlexBox = styled(Box)`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1.5)}px;
  justify-content: space-around;
`;

const StyledTypography = styled(Typography)`
  border-bottom: 1px dotted #000;
  cursor: pointer;
`;
