import React from 'react';
import { Box, Typography } from '@heycater/design-system';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
  count: number;
}

function DietaryTotal({ count }: Props) {
  const { t } = useTranslation('qualification');

  return (
    <Box mt={3} mb={5} display="flex" width={'100%'} justifyContent="center">
      <GridWrapper container spacing={2}>
        <Typography variant="body1" fontWeight={600}>
          {t('diet.pax')}
        </Typography>
        <Typography variant="body1">{count}</Typography>
      </GridWrapper>
    </Box>
  );
}

const GridWrapper = styled(Grid)`
  width: 100%;
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 1fr;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    display: flex;
    justify-content: center;
    gap: ${({ theme }) => theme.spacing(2)}px;
  }
`;

export default DietaryTotal;
