import React from 'react';

import { useQualification } from 'qualification/context';
import { QUALIFICATION_STATUS } from 'qualification/context/model';
import { questions } from 'qualification/schema/questions';
import QuantityStepperField from 'system/components/QuantityStepperField';

function PeopleCountStepper() {
  const { actions, state, answerOf, errorsOf } = useQualification();

  if (state.status !== QUALIFICATION_STATUS.answering) {
    return null;
  }

  const answer = answerOf(questions.peopleCount.id);
  const errors = errorsOf(questions.peopleCount.id);
  return (
    <QuantityStepperField
      name={questions.peopleCount.id}
      id={questions.peopleCount.id}
      value={answer || 0}
      error={!!errors?.length}
      onChange={(value) =>
        actions.answerQuestion(questions.peopleCount.id, value)
      }
    />
  );
}

export default PeopleCountStepper;
