import React from 'react';
import { Box, Typography } from '@heycater/design-system';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import PeopleCountStepper from 'qualification/components/question_types/BudgetQuestionWithPeopleCount/PeopleCountStepper';
import PopularValues from 'qualification/components/question_types/BudgetQuestionWithPeopleCount/PopularValues';
import { useQualification } from 'qualification/context';
import useCurrentUser from 'qualification/hooks/useCurrentUser';
import { questions } from 'qualification/schema/questions';
import {
  pricePerPersonQuestion,
  totalBudgetQuestion,
} from 'qualification/schema/questions/budgetQuestion';
import PriceStepperField from 'system/components/ButtonGroupSelect/PriceStepperField';

function BudgetQuestionWithPeopleCount() {
  const { answerOf, errorsOf, actions } = useQualification();
  const { t } = useTranslation();
  const { currentUserAccount } = useCurrentUser();
  const isManagedAccount =
    currentUserAccount?.owner?.salesforceCustomer?.isManagedAccount;

  const pricePerPersonAnswer = answerOf(pricePerPersonQuestion.id);
  const pricePerPersonErrors = errorsOf(pricePerPersonQuestion.id);
  const budgetAnswer = answerOf(totalBudgetQuestion.id);
  const budgetErrors = errorsOf(totalBudgetQuestion.id);

  return (
    <Wrapper>
      <Box mb={3}>
        <Typography as="label" htmlFor={questions.peopleCount.id}>
          {t('common:questions.min_order_number')}
        </Typography>
      </Box>
      <Box mb={3}>
        <PeopleCountStepper />
      </Box>

      <FullRowBox>
        <PopularValues />
      </FullRowBox>

      <Divider my={4} />

      <Box mb={2}>
        <Typography as="label" htmlFor={pricePerPersonQuestion.id}>
          {t('common:questions.budgetProPerson')}
        </Typography>
      </Box>

      <Box mb={2}>
        <PriceStepperField
          name={pricePerPersonQuestion.id}
          id={pricePerPersonQuestion.id}
          min={0}
          autoFocus={false}
          error={!!pricePerPersonErrors?.length}
          value={pricePerPersonAnswer ? Math.round(pricePerPersonAnswer) : 0}
          onChange={(val) => {
            actions.answerQuestion(pricePerPersonQuestion.id, val, {
              isManagedAccount,
            });
          }}
        />
      </Box>
      <Box mb={2}>
        <Typography as="label" htmlFor={totalBudgetQuestion.id}>
          {t('common:questions.totalBudget')}
        </Typography>
      </Box>
      <PriceStepperField
        name={totalBudgetQuestion.id}
        id={totalBudgetQuestion.id}
        min={0}
        autoFocus={false}
        error={!!budgetErrors?.length}
        value={budgetAnswer ? Math.floor(budgetAnswer) : 0}
        onChange={(val) =>
          actions.answerQuestion(totalBudgetQuestion.id, Math.floor(val), {
            isManagedAccount,
          })
        }
      />
      <FullRowBox mt={2}>
        <ErrorWrapper>
          {pricePerPersonErrors[0] || budgetErrors[0]}
        </ErrorWrapper>
      </FullRowBox>
    </Wrapper>
  );
}

const FullRowBox = styled(Box)`
  grid-column: 1 / 3;
`;

const ErrorWrapper = styled.div`
  color: #e86202;
  font-weight: 500;
`;

const Divider = styled(Box)`
  height: 2px;
  background-color: ${({ theme }) => theme.palette.grey[100]};
  width: 100%;
  grid-column: 1 / 3;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 441px;
  margin-left: auto;
  align-items: center;
  margin-right: auto;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: grid;
    grid-template-columns: 1fr auto;
  }
`;

export default BudgetQuestionWithPeopleCount;
