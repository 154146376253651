import React from 'react';
import { Box } from '@heycater/design-system';
import styled from 'styled-components';

import DietaryStepper from 'qualification/components/question_types/DietaryStepper';
import DietaryTotal from 'qualification/components/question_types/DietaryTotal';
import { useQualification } from 'qualification/context';
import { QUALIFICATION_STATUS } from 'qualification/context/model';
import { SCHEMA_STEPS } from 'qualification/schema/steps';

const step = SCHEMA_STEPS.dietary_restrictions;

function DietaryDistribution() {
  const { state, answerOf } = useQualification();

  if (state.status !== QUALIFICATION_STATUS.answering) {
    return null;
  }

  const peopleCountAnswer = answerOf('min_order_number');

  return (
    <Box maxWidth="500px" width="100%" mx={'auto'}>
      <Wrapper>
        {step.questions.map((childQuestion, index) => {
          return (
            <DietaryStepper
              autoFocus={index === 0}
              key={childQuestion.id}
              question={childQuestion}
            />
          );
        })}
      </Wrapper>
      <DietaryTotal count={peopleCountAnswer || 0} />
    </Box>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default DietaryDistribution;
