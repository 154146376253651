import React, { ReactNode, useEffect } from 'react';
import { Box } from '@heycater/design-system';
import { BoxProps } from '@material-ui/core';
import styled from 'styled-components';

import 'react-day-picker/dist/style.css';

import { useQualification } from 'qualification/context';
import { QUALIFICATION_STATUS } from 'qualification/context/model';

interface Props extends BoxProps {
  hasSidebar?: boolean;
  dateSelector: ReactNode;
  sideContent: ReactNode;
}

function DatePickerLayout({
  hasSidebar,
  dateSelector,
  sideContent,
  ...boxProps
}: Props) {
  const { actions, state, answerOf } = useQualification();

  useEffect(() => {
    if (answerOf('lead_type') === undefined)
      actions.answerQuestion('lead_type', 'private');
  }, [answerOf]);

  if (state.status !== QUALIFICATION_STATUS.answering) {
    return null;
  }

  return (
    <Wrapper $hasSidebar={hasSidebar} {...boxProps}>
      {dateSelector}
      {sideContent}
    </Wrapper>
  );
}

const Wrapper = styled(Box)<{ $hasSidebar?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  flex-direction: row;
  row-gap: 2rem;
  align-items: center;
  column-gap: 0rem;

  ${({ theme, $hasSidebar }) =>
    theme.breakpoints.up($hasSidebar ? 'lg' : 'md')} {
    column-gap: 2rem;
  }

  ${({ theme, $hasSidebar }) =>
    theme.breakpoints.up($hasSidebar ? 'md' : 'sm')} {
    align-items: stretch;
  }
`;

export default DatePickerLayout;
